
import axios from "axios";

const domain = process.env.VUE_APP_DOMAIN ?? 'https://us-central1-gestio-school-dev.cloudfunctions.net';
axios.defaults.baseURL = domain;

export default {
    create(payload) {
        return axios.post("v1_genesis/v1/contracts_fields/create", payload);
    },
    update(id, payload) {
        return axios.put(`v1_genesis/v1/contracts_fields/update/${id}`, payload);
    },
    remove(id) {
        return axios.delete(`v1_genesis/v1/contracts_fields/remove/${id}`);
    },
    delete(id) {
        return axios.delete(`v1_genesis/v1/contracts_fields/delete/${id}`);
    },
    get(id) {
        return axios.get(`v1_genesis/v1/contracts_fields/${id}`);
    },
    paginate(query) {
        return axios.get(`v1_genesis/v1/contracts_fields/paginate`, { params: query });
    },
    all() {
        return axios.get(`v1_genesis/v1/contracts_fields/`);
    }
}
